<script setup>

import MotionPicture520 from "@/components/MotionPicture520.vue";

const videoList = [
  // {
  //   name: '杭州之旅',
  //   time: '2023年秋',
  //   img: '/杭州封面.jpg',
  //   // size: 15088604,
  //   // src: 'http://localhost:8000/来听音乐节啦'
  //   src: '/rec/杭州之旅',
  // },
  {
    name: '南京之旅',
    time: '2023年冬',
    img: '/南京封面.jpg',
    // src: 'https://songjingjing.love/rec/南京之旅',
    // size: 23040271,
    src: '/rec/南京之旅'
  },
  {
    name: '云南之旅',
    time: '2023年夏',
    img: '/云南封面.jpg',
    // src: 'https://songjingjing.love/rec/云南之旅',
    // size: 40111984,
    src: '/rec/云南之旅',
  },
  {
    name: '武汉之旅',
    time: '2024年春',
    img: '/武汉封面.jpg',
    // src: 'https://songjingjing.love/rec/武汉之旅',
    // size: 15147984,
    src: '/rec/武汉之旅'
  },
  {
    name: '江西之旅',
    time: '2024年夏',
    img: '/江西封面.jpg',
    // src: 'https://songjingjing.love/rec/江西之旅',
    // size: 15147984,
    src: '/rec/江西之旅'
  },
  {
    name: '来听音乐节啦',
    time: '2024年春',
    img: '/音乐节封面.jpg',
    // src: 'https://songjingjing.love/rec/来听音乐节啦',
    // size: 81073579,
    src: '/rec/来听音乐节啦'
  },
  {
    name: '西湖闲游',
    time: '2024年夏',
    img: '/西湖封面.jpg',
    // src: 'https://songjingjing.love/rec/西湖闲游',
    // size: 13063452,
    src: '/rec/西湖闲游'
  }
]
</script>

<template>
  <div class="flex flex-wrap px-5 py-8 sm:p-20 xl:justify-between sm:justify-center items-center gap-x-10 gap-y-20
        xl:py-20 xl:p-40 ">
    <MotionPicture520 v-for="video in videoList" :video="video"/>
  </div>
</template>

<style scoped lang="scss">

</style>